<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("SQUAD_STATUS") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        v-for="(res, idx) in results"
        :key="idx"
        @click="selectTeam(idx)"
        :class="{ 'tab-selected': idx === teamSelected }"
      >
        {{ res.teamName }}
      </button>
    </div>

    <!-- Mobile -->
    <template v-if="isMobile">
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-full" v-if="mobileResults">
          <thead>
            <tr>
              <th
                class="matchList-content_table-title"
                :colspan="headerLabel.length"
              >
                {{ mobileResults.teamName }}
              </th>
            </tr>
            <tr>
              <th v-for="label in headerLabel" :key="label">
                {{ $t(label) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="mobileResults.injurySuspension.length !== 0">
              <tr
                v-for="(match, index) in mobileResults.injurySuspension"
                :key="index"
              >
                <td>{{ match.number }}</td>
                <td>{{ match.name }}</td>
                <td>{{ match.pos ? match.pos : "-" }}</td>
                <td>{{ match.reason }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="headerLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
            <tr class="matchList-content_table-subtitle">
              <th :colspan="7" class="matchList-content_separator">
                {{ $t("PREV_LINEUP") }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th
                v-for="label in subLabel"
                :key="label"
                colspan="2"
                class="matchList-space-half matchList-content_separator"
              >
                <button
                  @click="selectLineup(label.id)"
                  :class="{ active: label.id === lineupSelected }"
                >
                  {{ $t(label.label) }}
                </button>
              </th>
            </tr>
            <template v-if="lineup.length !== 0">
              <tr v-for="l in lineup" :key="l">
                <td>{{ l.number }}</td>
                <td colspan="2.5">{{ l.name }}</td>
                <td>{{ l.pos ? l.pos : "-" }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="headerLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>

    <!-- Desktop -->
    <template v-else>
      <div class="matchList-content_wrapper">
        <table
          class="matchList-content_table table-half"
          v-for="(res, idx) in results"
          :key="idx"
        >
          <thead>
            <tr>
              <th class="matchList-content_table-title" :colspan="6">
                {{ res.teamName }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th
                v-for="(label, i) in headerLabel"
                :key="label"
                :class="{ 'matchList-data_number': i === 0 }"
              >
                {{ $t(label) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="res.injurySuspension.length !== 0">
              <tr v-for="(match, index) in res.injurySuspension" :key="index">
                <td>{{ match.number }}</td>
                <td>{{ match.name }}</td>
                <td>{{ match.pos ? match.pos : "-" }}</td>
                <td>{{ match.reason }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="headerLabel.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="matchList-content_wrapper">
        <div
          v-for="(res, idx) in results"
          :key="idx"
          class="matchList-content_wrapper-child"
        >
          <table class="matchList-content_table table-full">
            <thead>
              <tr class="matchList-content_table-subtitle">
                <th :colspan="7" class="matchList-content_separator">
                  {{ $t("PREV_LINEUP") }}
                </th>
              </tr>
              <tr class="matchList-content_table-subtitle">
                <th
                  v-for="label in subLabel"
                  :key="label"
                  colspan="2"
                  class="matchList-space-half matchList-content_separator"
                >
                  {{ $t(label.label) }}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>

      <div class="matchList-content_wrapper">
        <div
          v-for="(res, idx) in results"
          :key="idx"
          class="matchList-content_wrapper-child"
        >
          <table
            class="matchList-content_table-child"
            v-for="(lineup, i) in res.previousLineup"
            :key="i"
          >
            <thead>
              <tr>
                <th
                  v-for="(label, i) in subHeaderLabel"
                  :key="label"
                  :class="{ 'matchList-data_number-child': i === 0 }"
                >
                  {{ $t(label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="lineup.length > 0">
                <tr v-for="data in lineup" :key="data">
                  <td>{{ data.number }}</td>
                  <td>{{ data.name }}</td>
                  <td>{{ data.pos ? data.pos : "-" }}</td>
                </tr>
              </template>
              <template v-else>
                <tr :style="{ border: '0.5px solid #4b616c' }">
                  <td class="no-stat" :colspan="subHeaderLabel.length">
                    {{ $t("NO_DATA") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </section>
  <!-- <Loading v-show="isLoading"></Loading>
  <div v-show="isNodata && !isLoading">
    {{ $t("NO_DATA") }}
  </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      headerLabel: ["NUMBER", "PLAYER", "POSITION", "REASONS_ABSENCE"],
      subHeaderLabel: ["NUMBER", "PLAYER", "POSITION"],
      subLabel: [
        { label: "STARTER", id: "starter" },
        { label: "RESERVE", id: "backup" },
      ],
      teamSelected: "homeTeamSquad",
      lineupSelected: "starter",
      lineup: null,
      matchId: "",
      results: null,
      mobileResults: null,
      isLoading: false,
      isNodata: false,
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.getSquadStatusData();
  },
  watch: {
    teamSelected(newVal) {
      this.mobileResults = this.results[newVal];
      this.lineup = this.mobileResults.previousLineup[this.lineupSelected];
    },
    lineupSelected(newVal) {
      this.lineup = this.mobileResults.previousLineup[newVal];
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    ...mapActions(["getSquadStatus"]),
    shiftArr(arr) {
      delete arr.matchId;
      return arr;
    },
    selectTeam(id) {
      this.teamSelected = id;
    },
    selectLineup(id) {
      this.lineupSelected = id;
    },
    async getSquadStatusData() {
      this.isLoading = true;

      const result = await this.getSquadStatus({ matchId: this.matchId });
      this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      if (!data.matchId) {
        this.isNoData = true;
      } else {
        this.isNodata = false;
        this.results = this.shiftArr(data);
        this.mobileResults = this.results[this.teamSelected];
        this.lineup = this.mobileResults.previousLineup[this.lineupSelected];
      }
    },
  },
};
</script>

<style scoped>
/* .matchList-container {
  width: 85%;
} */

.matchList-content_table-subtitle th:nth-child(2) {
  width: unset;
}
.matchList-content_table-subtitle th:nth-child(3) {
  width: unset;
}
.matchList-space-half {
  width: 50% !important;
}
.matchList-data_number {
  width: 6%;
}
.matchList-data_number-child {
  width: 12%;
}

.matchList-content_table-subtitle .matchList-data_name {
  width: 44% !important;
}
.matchList-content_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.matchList-content_wrapper-child {
  width: 48%;
  display: flex;
}

.matchList-content_table-child {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 0.5px solid #4b616c;
}

.matchList-content_wrapper-child .matchList-content_table-child:nth-of-type(1) {
  order: 2;
}
.matchList-content_table-child tbody {
  background: #212b30;
}
.matchList-content_table-child th,
.matchList-content_table-child td {
  padding: 0.375rem;
}

tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}

.matchList-content_table td,
.matchList-content_table th {
  border: unset;
  padding: 0.375rem;
}

@media (max-width: 768px) {
  .matchList-container {
    width: 95%;
  }

  .matchList-content_wrapper {
    margin: 1rem auto;
  }
  .matchList-content_wrapper-child.team-selected-flex {
    display: block;
  }
  .matchList-content_wrapper-child {
    width: 100%;
  }
}
</style>
