<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("HALF_FULL_TIME") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        @click="selectTeam(homeTeam.team)"
        :class="{ 'tab-selected': homeTeam.team === teamSelected }"
      >
        {{ homeTeam.name }}
      </button>
      <button
        @click="selectTeam(awayTeam.team)"
        :class="{ 'tab-selected': awayTeam.team === teamSelected }"
      >
        {{ awayTeam.name }}
      </button>
    </div>

    <div class="matchList-content_wrapper">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full">
          <thead>
            <tr>
              <th
                :colspan="positionLabel.length + 2"
                class="matchList-content_table-title"
              >
                {{
                  teamSelected === "homeTeam" ? homeTeam.name : awayTeam.name
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="teamData">
              <tr>
                <td
                  v-for="(l, i) in mobilePositionLabel"
                  :key="i"
                  :class="
                    i === 0 || i === 1 ? 'matchList-content_separator' : null
                  "
                >
                  {{ $t(l) }}
                </td>
              </tr>
              <tr v-for="(o, index) in toMobileArray(teamData)" :key="index">
                <td class="matchList-content_separator">
                  {{ $t(halfTimeTableAttr1[index + 1]) }}
                </td>
                <td class="matchList-content_separator">
                  {{ $t(halfTimeTableAttr2[index + 1]) }}
                </td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="positionLabel.length + 2">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="positionLabel.length + 2"></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table class="matchList-content_table  table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th v-for="attr in halfTimeTableAttr1" :key="attr">
                {{ $t(attr) }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="attr in halfTimeTableAttr2" :key="attr">
                {{ $t(attr) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="homeHalfTime">
              <tr v-for="(o, index) in homeHalfTime" :key="index">
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="halfTimeTableAttr1.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <table class="matchList-content_table table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th v-for="attr in halfTimeTableAttr1" :key="attr">
                {{ $t(attr) }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="attr in halfTimeTableAttr2" :key="attr">
                {{ $t(attr) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="awayHalfTime">
              <tr v-for="(o, index) in awayHalfTime" :key="index">
                <td>{{ $t(positionLabel[index]) }}</td>
                <td v-for="(n, i) in o" :key="i">{{ n }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="halfTimeTableAttr1.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["homeHalfTime", "awayHalfTime", "homeTeam", "awayTeam"],
  data() {
    return {
      positionLabel: ["TOTAL", "HOME", "AWAY"],
      mobilePositionLabel: ["HALF_TIME", "FULL_TIME", "TOTAL", "HOME", "AWAY"],
      halfTimeTableAttr1: [
        "HALF_TIME",
        "W",
        "W",
        "W",
        "DRAW",
        "DRAW",
        "DRAW",
        "L",
        "L",
        "L",
      ],
      halfTimeTableAttr2: [
        "FULL_TIME",
        "W",
        "DRAW",
        "L",
        "W",
        "DRAW",
        "L",
        "W",
        "DRAW",
        "L",
      ],
      teamSelected: this.homeTeam.team,
      teamData: this.homeHalfTime,
    };
  },
  watch: {
    homeTeam(newVal) {
      this.teamSelected = newVal.team;
    },
    homeHalfTime(newVal) {
      this.teamData = newVal;
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    selectTeam(id) {
      this.teamSelected = id;
      id === "homeTeam"
        ? (this.teamData = this.homeHalfTime)
        : (this.teamData = this.awayHalfTime);
    },
    toMobileArray(arr) {
      if (arr) {
        return eval(arr).reduce(
          (r, a, i) => (a.forEach((b, j) => ((r[j] = r[j] || [])[i] = b)), r),
          []
        );
      } else return "";
    },
  },
};
</script>

<style scoped>
.matchList-content_table {
  table-layout: fixed;
}
/* .matchList-content_table-subtitle th:nth-child(1) {
  width: 2rem;
} */
.matchList-content_table-subtitle th:nth-child(2),
.matchList-content_table-subtitle th:nth-child(3) {
  width: unset;
}
@media (max-width: 768px) {
  .matchList-content_table td {
    padding: 0.25rem 0;
  }
}
</style>
