<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("PLAYER_PERFORMANCE") }} ({{ $t("PREV_GAME") }})
    </div>
    <div class="matchList-tab" v-if="isMobile">
      <button
        v-for="(res, idx) in results"
        :key="idx"
        @click="selectTeam(idx)"
        :class="{ 'tab-selected': idx === teamSelected }"
      >
        {{ res.name }}
      </button>
    </div>
    <div class="matchList-content_wrapper">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full" v-if="mobileResults">
          <thead>
            <tr>
              <th class="matchList-content_table-title" :colspan="label.length">
                {{ mobileResults.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="(l, i) in label" :key="i">
                {{ $t(l) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="mobileResults.players.length !== 0">
              <tr v-for="(player, index) in mobileResults.players" :key="index">
                <td>{{ player.number }}</td>
                <td :style="{ textAlign: 'left' }">{{ player.name }}</td>
                <td>{{ player.position }}</td>
                <td>{{ player.rating }}</td>
              </tr>
              <tr>
                <td colspan="3" class="matchList-content_performance-rating">
                  Average score
                </td>
                <td>
                  {{ mobileResults.avgRating }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table
          class="matchList-content_table table-half"
          v-for="(res, idx) in results"
          :key="idx"
        >
          <thead>
            <tr>
              <th class="matchList-content_table-title" colspan="4">
                {{ res.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th
                v-for="(l, i) in label"
                :key="l"
                :class="
                  i === 0
                    ? 'matchList-content_performance-number'
                    : i === 1
                    ? 'matchList-content_performance-name'
                    : null
                "
              >
                {{ $t(l) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="res.players.length !== 0">
              <tr v-for="(player, index) in res.players" :key="index">
                <td>{{ player.number }}</td>
                <td :style="{ textAlign: 'left' }">{{ player.name }}</td>
                <td>{{ player.position }}</td>
                <td>{{ player.rating }}</td>
              </tr>
              <tr>
                <td colspan="3" class="matchList-content_performance-rating">
                  Average score
                </td>
                <td>
                  {{ res.avgRating }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      label: ["NUMBER", "PLAYER", "POSITION", "SCORE"],
      teamSelected: "homeTeam",
      isLoading: false,
      isNodata: false,
      results: null,
      mobileResults: null,
      matchId: "",
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.getPerformanceRating();
  },
  watch: {
    teamSelected(newVal) {
      this.mobileResults = this.results[newVal];
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    ...mapActions(["getPerformance"]),
    selectTeam(id) {
      this.teamSelected = id;
    },
    async getPerformanceRating() {
      this.isLoading = true;

      const result = await this.getPerformance({ matchId: this.matchId });
      this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      if (!data.homeTeam && !data.awayTeam) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.results = data;
        this.mobileResults = this.results[this.teamSelected];
      }
    },
  },
};
</script>

<style scoped>
/* .matchList-container {
  width: 95%;
} */
.matchList-content_performance-rating {
  text-align: left;
  text-indent: 1.25rem;
}
tr {
  border-top: 0.5px solid #4b616c;
  border-bottom: 0.5px solid #4b616c;
}

.matchList-content_table td,
.matchList-content_table th {
  border: unset;
}
</style>
