<template>
  <LeagueStanding />
  <SquadStatus />
  <PlayerPerformanceRating />
  <OddsResult side="h2h" :matchDetailScore="matchDetailScore" />
  <OddsResult side="home" :matchDetailScore="matchDetailScore" />
  <OddsResult side="away" :matchDetailScore="matchDetailScore" />
  <keep-alive>
    <router-view
      v-once
      :key="$route.name"
      @selectCompany="companySelectHandler"
      @selectDuration="durationSelectHandler"
    />
  </keep-alive>
  <GoalScoringStat :matchDetailScore="matchDetailScore" />
  <Next5Match />
  <div class="match-detail__stats-wrapper" v-show="!isShowNoData">
    <div class="match-detail__stats-header">
      {{ $t("MATCH_STATISTICS") }}
    </div>
    <div
      v-for="(o, index) in stateList"
      :key="index"
      v-show="!isLoading && !isShowNoData"
    >
      <div
        class="match-detail__stats-card"
        v-if="o.homeScore !== null && o.awayScore !== null"
      >
        <div class="match-detail__stats-title">{{ $t(o.title) }}</div>
        <div class="display-flex">
          <div class="match-detail__stats-container mr-05rem">
            <div class="match-detail__stats-number">{{ o.homeScore }}</div>
            <div class="match-detail__stats-progress-wrapper">
              <div
                class="match-detail__stats-progress-bar"
                :style="{
                  width:
                    (o.homeScore / (o.homeScore + o.awayScore)) * 100 + '%',
                }"
              ></div>
            </div>
          </div>
          <div class="match-detail__stats-container">
            <div class="match-detail__stats-progress-wrapper right">
              <div
                class="match-detail__stats-progress-bar"
                :style="{
                  width:
                    (o.awayScore / (o.homeScore + o.awayScore)) * 100 + '%',
                }"
              ></div>
            </div>
            <div class="match-detail__stats-number right">
              {{ o.awayScore }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading v-show="isLoading"></Loading>
  <div class="no-data" v-show="isShowNoData && !isLoading">
    {{ $t("NO_STATS") }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GoalScoringStat from "@/components/matchListings/GoalScoringStat.vue";
import LeagueStanding from "@/components/matchListings/LeagueStanding.vue";
import Next5Match from "@/components/matchListings/Next5Match.vue";
import SquadStatus from "@/components/matchListings/SquadStatus.vue";
import PlayerPerformanceRating from "@/components/matchListings/PlayerPerformanceRating.vue";
import OddsResult from "@/components/matchListings/OddsResult.vue";

export default {
  components: {
    GoalScoringStat,
    Next5Match,
    LeagueStanding,
    SquadStatus,
    PlayerPerformanceRating,
    OddsResult,
  },
  props: ["matchDetailScore"],
  data() {
    return {
      isShowNoData: true,
      isLoading: false,
      matchId: "",
      stateList: [
        {
          title: "SHOTS_TOTAL",
          id: "shotsTotal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_ON_GOAL",
          id: "shotsOngoal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "Shots off goal",
          id: "shotsOffgoal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_BLOCKED",
          id: "shotsBlocked",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_INSIDE_BOX",
          id: "shotsInsidebox",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SHOTS_OUTSIDE_BOX",
          id: "shotsOutsidebox",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "FOULS",
          id: "fouls",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "CORNER",
          id: "corners",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "OFFSIDES",
          id: "offsides",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "POSSESSION_TIME",
          id: "possestiontime",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "YELLOW_CARDS",
          id: "yellowcards",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "RED_CARDS",
          id: "redcards",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "SAVES",
          id: "saves",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "PASSES_TOTAL",
          id: "passesTotal",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "PASSES_ACCURATE",
          id: "passesAccurate",
          homeScore: null,
          awayScore: null,
        },
        {
          title: "PASSED_PERCENTAGE",
          id: "passesPct",
          homeScore: null,
          awayScore: null,
        },
      ],
      matchDetailStatesLeft: {},
      matchDetailStatesRight: {},
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  mounted() {
    this.getMatchDetailStatData();
  },
  methods: {
    ...mapActions(["getMatchDetailStat"]),

    async getMatchDetailStatData() {
      this.isLoading = true;

      const result = await this.getMatchDetailStat({ matchId: this.matchId });

      this.isLoading = false;

      if (result.result.length === 0) {
        this.isShowNoData = true;
      } else {
        this.isShowNoData = false;
        let homeResult = result.result[0];
        let awayResult = result.result[1];
        let isNoData = 0;
        let gg = this.stateList;

        for (let i = 0; i < this.stateList.length; i++) {
          switch (this.stateList[i].id) {
            case "shotsTotal":
              this.stateList[i].homeScore = homeResult.shotsTotal;
              this.stateList[i].awayScore = awayResult.shotsTotal;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "shotsOngoal":
              this.stateList[i].homeScore = homeResult.shotsOngoal;
              this.stateList[i].awayScore = awayResult.shotsOngoal;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "shotsOffgoal":
              this.stateList[i].homeScore = homeResult.shotsOffgoal;
              this.stateList[i].awayScore = awayResult.shotsOffgoal;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "shotsBlocked":
              this.stateList[i].homeScore = homeResult.shotsBlocked;
              this.stateList[i].awayScore = awayResult.shotsBlocked;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "shotsInsidebox":
              this.stateList[i].homeScore = homeResult.shotsInsidebox;
              this.stateList[i].awayScore = awayResult.shotsInsidebox;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "shotsOutsidebox":
              this.stateList[i].homeScore = homeResult.shotsOutsidebox;
              this.stateList[i].awayScore = awayResult.shotsOutsidebox;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "fouls":
              this.stateList[i].homeScore = homeResult.fouls;
              this.stateList[i].awayScore = awayResult.fouls;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "corners":
              this.stateList[i].homeScore = homeResult.corners;
              this.stateList[i].awayScore = awayResult.corners;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "offsides":
              this.stateList[i].homeScore = homeResult.offsides;
              this.stateList[i].awayScore = awayResult.offsides;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "possestiontime":
              this.stateList[i].homeScore = homeResult.possestiontime;
              this.stateList[i].awayScore = awayResult.possestiontime;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "yellowcards":
              this.stateList[i].homeScore = homeResult.yellowcards;
              this.stateList[i].awayScore = awayResult.yellowcards;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "redcards":
              this.stateList[i].homeScore = homeResult.redcards;
              this.stateList[i].awayScore = awayResult.redcards;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "saves":
              this.stateList[i].homeScore = homeResult.saves;
              this.stateList[i].awayScore = awayResult.saves;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "passesTotal":
              this.stateList[i].homeScore = homeResult.passesTotal;
              this.stateList[i].awayScore = awayResult.passesTotal;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "passesAccurate":
              this.stateList[i].homeScore = homeResult.passesAccurate;
              this.stateList[i].awayScore = awayResult.passesAccurate;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
            case "passesPct":
              this.stateList[i].homeScore = homeResult.passesPct;
              this.stateList[i].awayScore = awayResult.passesPct;
              if (
                this.stateList[i].homeScore == null &&
                this.stateList[i].awayScore == null
              ) {
                isNoData++;
              }
              break;
          }
        }
        if (isNoData == this.stateList.length) {
          this.isShowNoData = true;
        }
      }
    },
    getStateList() {
      //  this.statesList
    },
  },
};
</script>

<style>
.match-detail__stats-wrapper {
  margin: 0 auto;
}
.match-detail__stats-header {
  padding: 0 1rem 1rem 1rem;
  /* margin: 0 1rem; */
  text-align: left;
  color: var(--color-grey-light);
  font-size: 0.8rem;
}
.match-detail__stats-card {
  margin: 0 1rem;
  color: var(--color-theme-2);
  margin: 0 1rem 1.2rem 1rem;
}
.match-detail__stats-container {
  display: flex;
  width: 50%;
  margin-right: 0.5rem;
  font-size: 0.7rem;
  align-items: center;
}
.match-detail__stats-container:last-of-type {
  margin-right: 0;
}
.match-detail__stats-title {
  font-size: 0.75rem;
  margin-bottom: 0.4rem;
}
.match-detail__stats-number {
  margin-right: 0.5rem;
  width: 1rem;
}
.match-detail__stats-number.right {
  margin-right: 0;
  text-align: right;
}
.match-detail__stats-progress-wrapper {
  height: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #212b30;
}
.match-detail__stats-progress-wrapper.right {
  justify-content: flex-start;
  margin-right: 0.5rem;
}
.match-detail__stats-progress-bar {
  height: 100%;
  width: 0;
  background-color: var(--color-theme-2);
}

@media (min-width: 1200px) {
  .match-detail__stats-wrapper {
    width: 70%;
  }
}
</style>
