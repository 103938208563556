<template>
  <div class="matchList-header_wrapper">
    <MatchListDropdown
      v-if="listOption"
      :lists="listOption"
      :lengthSelected="lengthSelected"
      @lengthHandler="updateLength"
    />
    <CheckBox name="Same Side" :isSame="isSame" @isSameHandler="updateIsSame" />
    <CheckBox
      v-for="o in leagueOption"
      :key="o.leagueId"
      :name="o.leagueName"
      :leagueId="o.leagueId"
      @leagueHandler="updateLeague"
      :leagueList="leagueList"
    />
  </div>
</template>

<script>
import CheckBox from "@/components/ui/CheckBox.vue";
import MatchListDropdown from "@/components/ui/MatchListDropdown.vue";
export default {
  components: { CheckBox, MatchListDropdown },
  props: {
    options: Object,
    listOption: Array,
    lengthSelected: Number,
    leagueOption: Array,
    leagueList: Array,
    isSame: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    updateLength(data) {
      this.$emit("lengthUpdated", data);
    },
    updateLeague(data) {
      this.$emit("leagueUpdated", data);
    },
    updateIsSame() {
      this.$emit("isSameUpdated");
    },
  },
};
</script>

<style scoped>
.matchList-header_wrapper {
  display: flex;
  align-items: center;
}
</style>
