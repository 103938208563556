<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("NEXT_5") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        v-for="(res, idx) in results"
        :key="idx"
        @click="selectTeam(idx)"
        :class="{ 'tab-selected': idx === teamSelected }"
      >
        {{ res.name }}
      </button>
    </div>

    <div class="matchList-content_wrapper" v-if="!isNoData">
      <!-- Mobile -->
      <template v-if="isMobile">
        <table class="matchList-content_table table-full" v-if="mobileResults">
          <thead>
            <tr>
              <th class="matchList-content_table-title" :colspan="label.length">
                {{ mobileResults.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="l in label" :key="l">{{ $t(l) }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="mobileResults.matches.length !== 0">
              <tr v-for="(match, index) in mobileResults.matches" :key="index">
                <td>{{ matchDate(match.matchTime) }}</td>
                <td>{{ match.leagueEnShort }}</td>
                <td>{{ match.match }}</td>
                <td>{{ match.day + " " + $t("DAYS") }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="label.length"></td>
            </tr>
          </tfoot>
        </table>
      </template>

      <!-- Desktop -->
      <template v-else>
        <table
          class="matchList-content_table table-half"
          v-for="(res, idx) in results"
          :key="idx"
        >
          <thead>
            <tr>
              <th class="matchList-content_table-title" :colspan="label.length">
                {{ res.name }}
              </th>
            </tr>
            <tr class="matchList-content_table-subtitle">
              <th v-for="l in label" :key="l">{{ $t(l) }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="res.matches.length !== 0">
              <tr v-for="(match, index) in res.matches" :key="index">
                <td>{{ matchDate(match.matchTime) }}</td>
                <td>{{ match.leagueEnShort }}</td>
                <td>{{ match.match }}</td>
                <td>{{ match.day + " " + $t("DAYS") }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td class="no-stat" :colspan="label.length">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </template>
    </div>
  </section>
  <!-- <Loading v-show="isLoading"></Loading>
  <div v-show="isNoData && !isLoading">
    {{ $t("NO_DATA") }}
  </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "@/components/indicator/Loading.vue";

export default {
  components: { Loading },
  data() {
    return {
      label: ["DATE", "EVENT", "AGAINST", "APART"],
      teamSelected: "homeTeam",
      matchId: "",
      isLoading: false,
      isNoData: false,
      results: null,
      mobileResults: null,
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  watch: {
    teamSelected(newVal) {
      this.mobileResults = this.results[newVal];
    },
  },
  mounted() {
    this.getMatchDetailNext5Data();
  },
  methods: {
    ...mapActions(["getNextFiveMatch"]),

    async getMatchDetailNext5Data() {
      this.isLoading = true;

      const result = await this.getNextFiveMatch({ matchId: this.matchId });

      this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      if (!data.homeTeam && !data.awayTeam) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.results = data;
        this.mobileResults = this.results[this.teamSelected];
      }
    },
    matchDate(date) {
      return moment(date).format("MM-DD");
    },
    selectTeam(id) {
      this.teamSelected = id;
    },
  },
};
</script>

<style>
section {
  margin: 1.5rem auto;
}
.matchList-container {
  width: 100%;
}

.matchList-content_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
}

.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  width: 48%;
  text-align: center;
}

.matchList-content_table {
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #35454d;
  border-collapse: collapse;
  text-align: center;
}

.table-half {
  width: 48%;
}

.table-full {
  width: 100%;
}
.matchList-content_table-title {
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0.875rem 0 !important;
}

.matchList-content_title {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0.625rem 0 0.625rem 1rem;
}

.matchList-content_table-subtitle th:nth-child(2),
.matchList-content_table-subtitle th:nth-child(3) {
  width: 35%;
}
.matchList-content_table th {
  font-weight: 700;
}
.matchList-content_table th,
.matchList-content_table td {
  border: 0.5px solid #4b616c;
  padding: 0.625rem 0.25rem;
}

.matchList-content_separator {
  font-weight: 700;
  background: #35454d;
}

.matchList-content_table tbody {
  background: #212b30;
}

.matchList-content_table td.no-stat {
  padding: 0.625rem 0.25rem !important;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

@media (max-width: 768px) {
  .matchList-container {
    width: 95%;
  }
  .matchList-content_table {
    empty-cells: show;
  }
  .matchList-content_table-title,
  .matchList-content_title {
    font-size: 0.8rem;
    font-weight: 700;
    text-indent: unset;
  }
  .matchList-content_title {
    margin: 0.625rem 0;
  }
  .matchList-content_table .matchList-content_table-title {
    padding: 0.625rem 0;
  }
  .matchList-content_table th {
    border: 0.5px solid #4b616c;
    padding: 0.35rem 0;
    background: #35454d;
  }

  .matchList-content_table td {
    padding: 1rem 0;
  }
  .matchList-tab {
    display: flex;
  }
  .matchList-tab button {
    background: none;
    color: #6f8c9b;
    border: 0;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: relative;
    padding: 0 0 6px 0;
    margin-right: 1rem;
  }
  .matchList-tab button:hover,
  .matchList-tab button.tab-selected {
    color: #fff;
  }
  .matchList-tab button.tab-selected::after,
  .matchList-tab button:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: #6f8c9b;
    border-radius: 2px;
  }
  .matchList-content_table {
    border-radius: 0.5rem;
    overflow: hidden;
  }
  .matchList-content_table tfoot td {
    padding: 0 !important;
    height: 1.875rem;
  }
  .matchList-content_separator button {
    background: #35454d;
    border: 1px solid #4b616c;
    border-radius: 8px;
    color: #fff;
    width: 95%;
    cursor: pointer;
    padding: 0.3rem 0;
  }
  .matchList-content_separator button.active {
    background: #4b616c;
  }
}
</style>
