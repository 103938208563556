<template>
  <div class="relative dropdown">
    <div class="display-flex display-flex-center mr-1rem">
      <span class="mr-05rem">{{ $t("LATEST") }}</span>
      <div class="nav-pill" @click="matchLengthHandler">
        <span class="mr-03rem">{{ lengthSelected }}</span>
        <i class="svg-arrow__vertical ">
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            class="league-dropdown__svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z"
              fill="inherit"
            />
          </svg>
        </i>
      </div>
      <span>{{ $t("MATCHES") }}</span>
    </div>
    <div class="dropdown-wrapper" :class="{ selected: lengthShow }">
      <div class="ps ps--active-x ps--active-y">
        <div class="dropdown-container__wrapper">
          <div
            class="dropdown-container"
            v-for="l in lists"
            :key="l"
            @click="
              $emit('lengthHandler', l);
              matchLengthHandler();
            "
          >
            <div class="dropdown-container__name">
              <span>{{ l }}</span>
            </div>
            <div
              class="dropdown-container__tick"
              :class="{ selected: lengthSelected === l }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lists", "lengthSelected"],
  data() {
    return {
      lengthShow: false,
    };
  },
  methods: {
    matchLengthHandler() {
      this.lengthShow = !this.lengthShow;
    },
  },
};
</script>

<style scoped>
.league-dropdown__svg {
  fill: white;
}
.dropdown-wrapper {
  left: calc(50% - 2.5rem);
  min-width: 5rem;
}
.nav-pill {
  min-width: 35px;
}
.dropdown {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>
