<template>
  <LeagueOdds
    :homeOdds="homeOdds"
    :awayOdds="awayOdds"
    :homeTeam="homeTeam"
    :awayTeam="awayTeam"
  />
  <GoalScoringDistribution
    :homeTotalGoalScore="homeTotalGoalScore"
    :awayTotalGoalScore="awayTotalGoalScore"
    :homeTeam="homeTeam"
    :awayTeam="awayTeam"
  />
  <GoalScoringHalfTime
    :homeHalfTime="homeHalfTime"
    :awayHalfTime="awayHalfTime"
    :homeTeam="homeTeam"
    :awayTeam="awayTeam"
  />
  <GoalScoringTime
    :homeShootTime="homeShootTime"
    :awayShootTime="awayShootTime"
    :homeTeam="homeTeam"
    :awayTeam="awayTeam"
  />
</template>

<script>
import axios from "axios";
import GoalScoringDistribution from "./goalScoringStat/GoalScoringDistribution.vue";
import GoalScoringHalfTime from "./goalScoringStat/GoalScoringHalfTime.vue";
import GoalScoringTime from "./goalScoringStat/GoalScoringTime.vue";
import LeagueOdds from "./goalScoringStat/LeagueOdds.vue";
import { mapActions } from "vuex";

export default {
  components: {
    GoalScoringDistribution,
    GoalScoringHalfTime,
    GoalScoringTime,
    LeagueOdds,
  },
  props: {
    matchDetailScore: Object,
  },
  data() {
    return {
      matchId: "",
      results: "",
      isLoading: false,
      isNoData: false,
      homeTotalGoalScore: "",
      awayTotalGoalScore: "",
      homeHalfTime: "",
      awayHalfTime: "",
      homeShootTime: "",
      awayShootTime: "",
      homeTeam: "",
      awayTeam: "",
      homeOdds: "",
      awayOdds: "",
    };
  },
  created() {
    this.matchId = this.$route.params.matchId;
  },
  mounted() {
    this.getGoalScoringStatsData();
  },
  watch: {
    matchDetailScore(newVal) {
      this.awayTeam = {
        team: "awayTeam",
        name: newVal.awayTeamName,
      };

      this.homeTeam = {
        team: "homeTeam",
        name: newVal.homeTeamName,
      };
    },
  },
  methods: {
    ...mapActions(["getGoalScoringStats"]),
    async getGoalScoringStatsData() {
      this.isLoading = true;

      const result = await this.getGoalScoringStats({ matchId: this.matchId });

      this.isLoading = false;

      this.dataValidation(result.result);
    },
    dataValidation(data) {
      this.homeTeam = {
        team: "homeTeam",
        name: this.matchDetailScore.homeTeamName,
      };
      this.awayTeam = {
        team: "awayTeam",
        name: this.matchDetailScore.awayTeamName,
      };

      if (data.homeTotalGoalScore) {
        this.homeTotalGoalScore = eval(data.homeTotalGoalScore);
      }
      if (data.awayTotalGoalScore) {
        this.awayTotalGoalScore = eval(data.awayTotalGoalScore);
      }
      if (data.homeHalfTime) {
        this.homeHalfTime = eval(data.homeHalfTime);
      }
      if (data.awayHalfTime) {
        this.awayHalfTime = eval(data.awayHalfTime);
      }
      if (data.homeShootTime) {
        this.homeShootTime = eval(data.homeShootTime);
      }
      if (data.awayShootTime) {
        this.awayShootTime = eval(data.awayShootTime);
      }
      if (data.homeOdds) {
        this.homeOdds = eval(data.homeOdds);
      }
      if (data.awayOdds) {
        this.awayOdds = eval(data.awayOdds);
      }
    },
  },
};
</script>

<style scoped></style>
