<template>
  <section class="matchList-container">
    <div class="matchList-content_title">
      {{ $t("LEAGUE_TREND") }}
    </div>

    <!-- Mobile Team Selector -->
    <div class="matchList-tab" v-if="isMobile">
      <button
        @click="selectTeam(homeTeam.team)"
        :class="{ 'tab-selected': homeTeam.team === teamSelected }"
      >
        {{ homeTeam.name }}
      </button>
      <button
        @click="selectTeam(awayTeam.team)"
        :class="{ 'tab-selected': awayTeam.team === teamSelected }"
      >
        {{ awayTeam.name }}
      </button>
    </div>

    <!-- Mobile -->
    <template v-if="isMobile">
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-full">
          <thead>
            <tr>
              <th :colspan="10" class="matchList-content_table-title">
                {{
                  teamSelected === "homeTeam" ? homeTeam.name : awayTeam.name
                }}
              </th>
            </tr>
            <tr>
              <th
                v-for="label in positionLabel.slice(1, positionLabel.length)"
                :key="label"
                class="matchList-space-half matchList-content_separator"
                :colspan="5"
              >
                <button
                  @click="selectTab(label.id)"
                  :class="{ active: tabSelected === label.id }"
                >
                  {{ $t(label.name) }}
                </button>
              </th>
            </tr>
            <tr>
              <th :colspan="2">{{ $t(positionLabel[0].name) }}</th>
              <th
                v-for="l in tabSelected === 'handicap'
                  ? tableAttr.slice(1, 5)
                  : tableAttr.slice(5, tableAttr.length)"
                :key="l"
                :colspan="2"
              >
                {{ $t(l) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="teamData">
              <tr
                v-for="(data, idx) in dataSplit(teamData, 'first')"
                :key="idx"
              >
                <td :colspan="2">{{ $t(bodyLabel[idx]) }}</td>
                <template v-if="data">
                  <td
                    :colspan="
                      idx === 3 && i === 0 && tabSelected === 'handicap'
                        ? 6
                        : idx === 3 && i === 0 && tabSelected === 'goals'
                        ? 8
                        : 2
                    "
                    v-for="(d, i) in dataSplitMobile(data, idx)"
                    :key="i"
                  >
                    {{
                      idx === 3 && i === 0 && tabSelected === "handicap"
                        ? dataTranslation(d, "handicap")
                        : idx === 3 && i === 0 && tabSelected === "goals"
                        ? dataTranslation(d, "goals")
                        : d
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="8">
                    {{ $t("NO_DATA") }}
                  </td>
                </template>
              </tr>
              <tr>
                <th :colspan="2">{{ $t(position2Label[0].name) }}</th>
                <th
                  v-for="l in tabSelected === 'handicap'
                    ? tableAttr.slice(1, 5)
                    : tableAttr.slice(5, tableAttr.length)"
                  :key="l"
                  :colspan="2"
                >
                  {{ $t(l) }}
                </th>
              </tr>
              <tr v-for="(data, idx) in dataSplit(teamData, 'last')" :key="idx">
                <td :colspan="2">{{ $t(bodyLabel[idx]) }}</td>
                <template v-if="data">
                  <td
                    :colspan="
                      idx === 3 && i === 0 && tabSelected === 'handicap'
                        ? 6
                        : idx === 3 && i === 0 && tabSelected === 'goals'
                        ? 8
                        : 2
                    "
                    v-for="(d, i) in dataSplitMobile(data, idx)"
                    :key="i"
                  >
                    {{
                      idx === 3 && i === 0 && tabSelected === "handicap"
                        ? dataTranslation(d, "handicap")
                        : idx === 3 && i === 0 && tabSelected === "goals"
                        ? dataTranslation(d, "goals")
                        : d
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="8">
                    {{ $t("NO_DATA") }}
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="10">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="10"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>

    <!-- Desktop -->
    <template v-else>
      <div class="matchList-content_wrapper">
        <table class="matchList-content_table table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th :colspan="tableAttr.length + 1">{{ homeTeam.name }}</th>
            </tr>
            <tr>
              <th
                :colspan="l.span"
                v-for="l in positionLabel"
                :key="l.name"
                :style="{ backgroundColor: l.color }"
              >
                {{ $t(l.name) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="matchlist-content_table-fixed-height">
              <td></td>
              <td v-for="attr in tableAttr" :key="attr">{{ $t(attr) }}</td>
            </tr>
            <template v-if="homeOdds">
              <tr
                v-for="(odds, idx) in dataSplit(homeOdds, 'first')"
                :key="idx"
              >
                <td>{{ $t(bodyLabel[idx]) }}</td>
                <template v-if="odds">
                  <td
                    v-for="(o, i) in odds"
                    :key="i"
                    :colspan="
                      idx === 3 && i === 1 ? 3 : idx === 3 && i === 3 ? 4 : 1
                    "
                  >
                    {{
                      idx === 3 && i === 1
                        ? dataTranslation(o, "handicap")
                        : idx === 3 && i === 3
                        ? dataTranslation(o, "goals")
                        : o
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="tableAttr.length + 1">
                    {{ $t("NO_DATA") }}
                  </td>
                </template>
              </tr>
              <tr>
                <th
                  :colspan="l.span"
                  v-for="l in position2Label"
                  :key="l.name"
                  :style="{ backgroundColor: l.color }"
                >
                  {{ $t(l.name) }}
                </th>
              </tr>
              <tr v-for="(odds, idx) in dataSplit(homeOdds, 'last')" :key="idx">
                <td>{{ $t(bodyLabel[idx]) }}</td>
                <template v-if="odds">
                  <td
                    v-for="(o, i) in odds"
                    :key="i"
                    :colspan="
                      idx === 3 && i === 1 ? 3 : idx === 3 && i === 3 ? 4 : 1
                    "
                  >
                    {{
                      idx === 3 && i === 1
                        ? dataTranslation(o, "handicap")
                        : idx === 3 && i === 3
                        ? dataTranslation(o, "goals")
                        : o
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="tableAttr.length + 1">
                    {{ $t("NO_DATA") }}
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="tableAttr.length + 1">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <table class="matchList-content_table table-half">
          <thead>
            <tr class="matchList-content_table-subtitle">
              <th :colspan="tableAttr.length + 1">{{ awayTeam.name }}</th>
            </tr>
            <tr>
              <th
                :colspan="l.span"
                v-for="l in positionLabel"
                :key="l.name"
                :style="{ backgroundColor: l.color }"
              >
                {{ $t(l.name) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="matchlist-content_table-fixed-height">
              <td></td>
              <td v-for="attr in tableAttr" :key="attr">{{ $t(attr) }}</td>
            </tr>
            <template v-if="awayOdds">
              <tr
                v-for="(odds, idx) in dataSplit(awayOdds, 'first')"
                :key="idx"
              >
                <td>{{ $t(bodyLabel[idx]) }}</td>
                <template v-if="odds">
                  <td
                    v-for="(o, i) in odds"
                    :key="i"
                    :colspan="
                      idx === 3 && i === 1 ? 3 : idx === 3 && i === 3 ? 4 : 1
                    "
                  >
                    {{
                      idx === 3 && i === 1
                        ? dataTranslation(o, "handicap")
                        : idx === 3 && i === 3
                        ? dataTranslation(o, "goals")
                        : o
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="tableAttr.length + 1">
                    {{ $t("NO_DATA") }}
                  </td>
                </template>
              </tr>
              <tr>
                <th
                  :colspan="l.span"
                  v-for="l in position2Label"
                  :key="l.name"
                  :style="{ backgroundColor: l.color }"
                >
                  {{ $t(l.name) }}
                </th>
              </tr>
              <tr v-for="(odds, idx) in dataSplit(awayOdds, 'last')" :key="idx">
                <td>{{ $t(bodyLabel[idx]) }}</td>
                <template v-if="odds">
                  <td
                    v-for="(o, i) in odds"
                    :key="i"
                    :colspan="
                      idx === 3 && i === 1 ? 3 : idx === 3 && i === 3 ? 4 : 1
                    "
                  >
                    {{
                      idx === 3 && i === 1
                        ? dataTranslation(o, "handicap")
                        : idx === 3 && i === 3
                        ? dataTranslation(o, "goals")
                        : o
                    }}
                  </td>
                </template>
                <template v-else>
                  <td :colspan="tableAttr.length + 1">
                    {{ $t("NO_DATA") }}
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :colspan="tableAttr.length + 1">
                  {{ $t("NO_DATA") }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["homeOdds", "awayOdds", "homeTeam", "awayTeam"],
  data() {
    return {
      positionLabel: [
        { name: "FULL_MATCH", span: 2, color: "#6F8C9B" },
        { name: "HANDICAP", span: 4, color: "#5C7784", id: "handicap" },
        { name: "GOALS", span: 4, color: "#4B616C", id: "goals" },
      ],
      position2Label: [
        { name: "HALF_TIME", span: 2, color: "#6F8C9B" },
        { name: "HANDICAP", span: 4, color: "#5C7784" },
        { name: "GOALS", span: 4, color: "#4B616C" },
      ],
      tableAttr: [
        "MATCH",
        "W",
        "DRAW",
        "L",
        "WIN_RATE",
        "BIG_BALL",
        "BIG_BALL_RATE",
        "SMALL_BALL",
        "SMALL_BALL_RATE",
      ],
      bodyLabel: ["TOTAL", "HOME", "AWAY", "LAST_6"],
      teamSelected: this.homeTeam.team,
      teamData: this.homeOdds,
      tabSelected: "handicap",
    };
  },
  watch: {
    homeTeam(newVal) {
      this.teamSelected = newVal.team;
    },
    homeOdds(newVal) {
      this.teamData = newVal;
    },
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  methods: {
    selectTeam(id) {
      this.teamSelected = id;
      id === "homeTeam"
        ? (this.teamData = this.homeOdds)
        : (this.teamData = this.awayOdds);
      console.log(this.homeOdds);
    },
    selectTab(id) {
      this.tabSelected = id;
    },
    dataTranslation(data, table) {
      const dataArray = data.split("");
      if (table === "handicap") {
        return dataArray
          .map((d) => {
            return d === "0"
              ? this.$t("L")
              : d === "1"
              ? this.$t("DRAW")
              : this.$t("W");
          })
          .join(" ");
      }
      if (table === "goals") {
        return dataArray
          .map((d) => {
            return d === "0"
              ? this.$t("SMALL")
              : d === "1"
              ? this.$t("DRAW")
              : this.$t("BIG");
          })
          .join(" ");
      }
    },
    dataSplit(data, table) {
      const odds = eval(data);
      if (table === "first") {
        return odds.slice(0, Math.ceil(odds.length / 2));
      }
      if (table === "last") {
        return odds.slice(-Math.ceil(odds.length / 2));
      }
    },
    dataSplitMobile(data, row) {
      if (data) {
        if (this.tabSelected === "handicap") {
          if (row === 3) {
            return data.slice(1, 3);
          } else return data.slice(1, 5);
        } else if (this.tabSelected === "goals") {
          if (row === 3) {
            return data.slice(3);
          } else return data.slice(5);
        }
      } else return null;
    },
  },
};
</script>

<style scoped>
/* .matchList-content_table th {
  border: unset;
  padding: 0.35rem;
} */
.matchList-space-half {
  border: unset;
  width: 50%;
}
.matchlist-content_table-fixed-height {
  height: 35px;
}
@media (max-width: 768px) {
  table {
    table-layout: fixed;
  }
  .matchList-content_table th,
  .matchList-content_table td {
    padding: 0.625rem 0.25rem;
  }
}
</style>
