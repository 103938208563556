<template>
  <div class="matchList-checkbox_wrapper">
    <div
      class="display-flex-center"
      @click="
        name === 'Same Side'
          ? $emit('isSameHandler')
          : $emit('leagueHandler', leagueId)
      "
    >
      <div
        class="matchList-checkbox_box"
        :class="{
          selected:
            name === 'Same Side' ? isSame : leagueList.includes(leagueId),
        }"
      ></div>
      <span>{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "leagueId", "leagueList", "isSame"],
};
</script>

<style scoped>
.matchList-checkbox_wrapper {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  margin: 0 0.5rem;
  max-width: 100px;
}
.matchList-checkbox_box {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  min-height: 1.25rem;
  margin-right: 0.375rem;
  background: url("../../../static/images/icons/icon_filter_tick_unselect.png")
    center/contain no-repeat;
}
.matchList-checkbox_box.selected {
  background: url("../../../static/images/icons/icon_filter_tick_white_selected.png")
    center/contain no-repeat;
}
</style>
